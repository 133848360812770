import React from "react"

  function FormEmbed({ form }){
  return (
    <section className="content white-background centered-form-holder">
      <h3 dangerouslySetInnerHTML={{ __html: form }}/>
      <div className="sixty-spacer"></div>
          </section>
  )
  }


export default FormEmbed
